<template>
  <div class="heads" id="select-head">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'HeadSelect',
    props: ['exclude', 'head'],
    components: {
      Select2
    },
    mounted() {
      if (this.head){
        this.$refs.select2.select2.append(
          `<option selected value="${this.head.id}">${this.head.form}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a head",
          allowClear: false,
          width: '100%',
          dropdownParent: "#select-head",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/heads/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(head => {
                  return {
                    id: head.id,
                    text: head.form,
                    head: head
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.selected ? event.head : null)
      }
    }
  }
</script>
