<template>
  <div>
    <PageTitle v-bind:title="'HCC Statistics'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <h6>Mode</h6>
          <b-form-select
            v-model="mode"
            name="mode-select"
            :options="modes"
            v-on:change="head = null; patterns = ''"
            class="mb-4">
          </b-form-select>
        </b-col>
        <b-col v-if="this.mode != 'head_doc_corp'">
          <h6>Shortname Pattern(s)</h6>
          <b-form-input
            placeholder="Pattern(s) (separate multiple patterns by comma)"
            v-model="patterns"
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col v-else>
          <h6>Head</h6>
          <HeadSelect
            @select="head = $event"
           />
        </b-col>
        <b-col>
          <b-button
            :disabled="(patterns.length == 0 && (this.mode ==  'hcc_doc_corp' || this.mode ==  'shortname_doc_corp')) || (!this.head && this.mode == 'head_doc_corp') || !this.mode"
            @click="calculateStats"
            size="sm"
            class="mt-custom-form"
          >
            Download Stats
            <b-icon icon="arrow-down" aria-hidden="true"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
* >>> .small-title legend {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
* >>> .select2 .selection {
  line-height: 1.12 !important;
}

* >>> .select2-container--default .select2-selection--multiple {
  border: 1px solid #ced4d9;
}

.mt-custom-form {
  margin-top: 20px;
}

.scroll-vertical {
  height: 100vh;
  overflow-y: auto;
}
</style>

<script>
// import { mapState } from "vuex";
import PageTitle from "@/components/PageTitle.vue";
import HeadSelect from "@/components/Scripta/HeadSelect.vue";
// import Select2 from "v-select2-component";

export default {
  name: "HCCStats",
  components: {
    PageTitle,
    HeadSelect
    // Select2,
  },

  data() {
    return {
      patterns: "",
      modes: [
          { value: "hcc_doc_corp", text: "HCC (documents per corpus)" },
          { value: "shortname_doc_corp", text: "Shortname (documents per corpus)" },
          { value: "head_doc_corp", text: "head (documents per corpus)" }
      ],
      mode: null,
      head: null
    };
  },

  methods: {
    async calculateStats() {
      let response = await this.$store.dispatch("calculateStats", {
        patterns: this.patterns,
        mode: this.mode,
        head: this.head
      })
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  },

};
</script>

